<template>
    <!--
        Planning general
    -->

    <div class="row">
        <div :class="clase">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header ">
                            <h3 @click="test1235()" class="card-title"><i class="fas fa-calendar-alt mr-2"></i>{{
                                $t('general.Visitas') }}
                            </h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" title="Filtro"
                                    v-on:click="mostrarfiltroavz = !mostrarfiltroavz; mostrarfiltrooperarios = false;">
                                    <i class="fas fa-filter"></i>
                                </button>
                                <button type="button" class="btn btn-tool" title="ensanchar"
                                    v-on:click="cambiarclase()">
                                    <i :class="icono"></i>
                                </button>
                                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                                    <i class="fas fa-expand"></i>
                                </button>
                                <button type="button" class="btn btn-tool" title="Leyenda" @click="toggle">
                                    <i class="fas fa-info"></i>
                                </button>
                                <OverlayPanel ref="op">
                                    <h4 style="text-align:center">{{ $t('general.leyenda') }}</h4>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style="width: 228px;background-color: rgb(52, 131, 171)">
                                                    {{$t('general.operarioocupado') }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 228px;background-color: rgb(235, 235, 235)">
                                                    {{$t('general.finalizadas') }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 228px;background-color: rgb(255, 232, 140)">
                                                    {{$t('general.pendientes') }}</td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="width: 228px; position:unset !important;background-color: rgb(0, 204, 0)">
                                                    {{ $t('general.vacaciones') }}</td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="width: 228px;background-color:#476268; color: #eee;font-size:12px;">
                                                    {{ $t('general.bloqueado') }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 228px;background-color: #cb55ac;">
                                                    {{$t('general.prioritaria') }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 228px;background-color: rgb(252, 175, 62)">
                                                    {{$t('general.conprecita') }}</td>
                                            </tr>
                                            <tr>
                                                <td style="width: 228px;background-color: rgb(255, 70, 70)">
                                                    <div>{{ $t('general.porconfirmar') }}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </OverlayPanel>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <!--<keep-alive>-->
                            <div v-if="mostrarfiltroavz">
                                <!-- <div class="row">
                                    <div class="col-md-3">
                                    </div>
                                    <div class="col-md-6">
                                        <select v-model="especioperarioseleccionado" class="form-control input-sm">
                                            <option value="">{{ $t('general.Selecciona Especialidad') }}</option>
                                            <option :value="especi.id_especialidad" v-for="especi in especioperario"
                                                :key="especi.id_especialidad">{{ especi.nombre }}</option>
                                        </select>
                                    </div>
                                </div>-->
                                <div class="row">
                                    <div class="col-md-1">
                                        <Button type="button" icon="pi pi-calendar" style="height: 90%;margin-left:25%"
                                            title="Calendario" v-on:click="togglecalen"></Button>
                                    </div>
                                    <div class="col-md-2">
                                        <select v-model="especioperarioseleccionado" class="form-control input-sm">
                                            <option value="" selected="true" hidden disabled="disabled">{{
                                                $t('general.Selecciona Especialidad') }}</option>
                                            <option :value="especi.id_especialidad" v-for="especi in especioperario"
                                                :key="especi.id_especialidad">{{ especi.nombre }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2" v-if="gestiontipos == true">
                                        <select v-model="tiposservicioseleccionado" class="form-control input-sm">
                                            <option selected="true" hidden disabled="disabled" value="">{{
                                                $t('general.Tipo de Servicio') }}</option>
                                            <option :value="servicio.id" v-for="servicio in tiposservicio"
                                                :key="servicio.id">{{ servicio.nombre }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2" v-if="equipos">
                                        <select v-model="marcaseleccionada" class="form-control input-sm">
                                            <option selected="true" hidden disabled="disabled" value="">{{
                                                $t('general.Marca') }}</option>
                                            <option :value="marca.id_marca" v-for="marca in marcas"
                                                :key="marca.id_marca">{{ marca.nombre_marca }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2" v-if="equipos">
                                        <select v-model="sat" class="form-control input-sm">
                                            <option selected="true" hidden disabled="disabled" value="">SAT</option>
                                            <option value="1">SAT {{ $t('general.si') }}</option>
                                            <option value="0">SAT {{ $t('general.no') }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2" v-if="guardias == true">
                                        <select v-model="guardia" class="form-control input-sm">
                                            <option selected="true" hidden disabled="disabled" value="">{{
                                                $t('general.guardia') }}</option>
                                            <option :value="true">{{ $t('general.guardia') }} {{ $t('general.si') }}
                                            </option>
                                            <option :value="false">{{ $t('general.guardia') }} {{ $t('general.no') }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-1">
                                        <Button type="button" icon="pi pi-filter-slash" style="height: 90%;"
                                            title="Reiniciar Filtros" v-on:click="reiniciarfiltros()"></Button>
                                    </div>
                                </div>

                            </div>
                            <MultiSelect v-model="operariosseleccionados" placeholder="Selecciona Operario"
                                :options="operariosagrupados" optionLabel="nombre" optionValue="idoperario" filter
                                optionGroupLabel="label" optionGroupChildren="items" display="chip"
                                :maxSelectedLabels="10" selectedItemsLabel="'{0} Operarios Seleccionados'"
                                scrollHeight="400px" class="w-full md:w-20rem" style="width: 100%" />
                            <p style="font-size:xx-small">&nbsp;</p>
                            <Slider @change="test1235()" :min="0" :max="maximoscroll" v-model="slider"
                                class="w-14rem" />
                            <p style="font-size:xx-small">&nbsp;</p>
                            <component v-bind:is="component" v-if="operarioscargados==true" :fechacal="fechacal"
                                :cambios="cambios" :operarios="operarios"
                                :operariosseleccionados="operariosseleccionados" />
                            <!--</keep-alive>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <OverlayPanel :dismissable="true" ref="calen">

        <Calendar v-model="fechacal" inline />

    </OverlayPanel>
</template>
<script>
import servicioplanningcalendario from './PlanningGlobal/PlanningCalendario.vue';
import Checkbox from 'primevue/checkbox';
import OverlayPanel from 'primevue/overlaypanel';
import MultiSelect from 'primevue/multiselect';
import { PwgsApi } from '../../services/PwgsApi';
import Slider from 'primevue/slider';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
export default ({
    props: [],
    setup() {
        return;
    },
    components: {
        'servicio-planning-calendario': servicioplanningcalendario,
        Checkbox, OverlayPanel, MultiSelect, Slider, Dialog, Calendar
    },
    data() {
        return {
            equipos:false,
            operariosagrupados:[],
            component: "servicio-planning-calendario",
            visitas: '',
            operarios: [],
            fechacal:null,
            mostrarfiltrooperarios: false,
            operariosseleccionados: '',
            mostrarfiltroavz: true,
            especioperario: '',
            especioperarioseleccionado: '',
            marcas: '',
            slider:null,
            marcaseleccionada: '',
            sat: '',
            tiposservicio: '',
            tiposservicioseleccionado: '',
            cambios: false,
            clase: "col-md-12",
            icono: "fas fa-minus",
            guardia: '',
            titulo: "Mapa",
            operarioscargados: false,
            maximoscroll: 10000,
            gestiontipos: false,
            guardias:false,
        }
    },
    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        togglecalen(event) {
            this.$refs.calen.toggle(event);
        },
        cambiarclase() {
            if (this.clase == "col-md-8") {
                this.clase = "col-md-12";
                this.icono = "fas fa-minus";
                this.$parent.mostrarf();
            }
            else {
                this.clase = "col-md-8";
                this.icono = "fas fa-plus";
                this.$parent.mostrarf2();
            }
        },
        reiniciaroperarios() {
            let aux = this.operariosseleccionados;
            this.operariosseleccionados = null;
            setTimeout(() => {
                this.operariosseleccionados = aux;
            }, 2000);
        },
        reiniciarfiltros() {
            this.cambios = false;
            this.marcaseleccionada = '';
            this.tiposservicioseleccionado = '';
            this.sat = '';
            this.especioperarioseleccionado = '';
            this.guardia = '';
            //this.filtraroperarios();
        },
        reiniciarfiltrosmenosespecialidad() {
            this.cambios = false;
            this.marcaseleccionada = '';
            this.tiposservicioseleccionado = '';
            this.sat = '';
            this.guardia = '';
            var aux = this.especioperarioseleccionado;
            console.log('auxi',aux);
            this.especioperarioseleccionado = '';
            setTimeout(() => {
                this.especioperarioseleccionado = aux;
            }, 2000);            
            //this.filtraroperarios();
        },
        async cargaroperarios() {
            //GET /modulos/pwgsapi/index.php/operarios/simple
            let datos;
            const api = new PwgsApi();
            datos = await api.post("planning-pwgs/0/operarios");
            console.log("opear1",Object.values(datos))
            this.operarios = Object.values(datos);            
            console.log("opear", this.operarios);
            this.operarios.sort((a, b) => a.nombre.localeCompare(b.nombre));
            console.log('operariosc', this.operarios);
            var operariosgrupos = [
                {'label':'Contratados', 'items':[]},
                {'label':'Autónomos', 'items':[]}]
                this.operarios.forEach(operario => {
                if (operario.tipo_operario=='contratado'){
                    operariosgrupos[0].items.push(operario);
                }else{
                    operariosgrupos[1].items.push(operario);
                }
            });
            this.operariosagrupados = operariosgrupos;
            console.log('operariosdef', this.operarios);
            this.operarioscargados = true;

        },
        sort(a, b) {
            return a.DepartmentName.localeCompare(b.DepartmentName);
        },
        test1235() {
            
            console.log("prueba123", document.getElementsByClassName("day-view"));
            if (document.getElementsByClassName('day-view')[0].scrollLeftMax) {
                this.maximoscroll = document.getElementsByClassName('day-view')[0].scrollLeftMax;
            }
            if (document.getElementsByClassName('day-view')[0].scrollWidth) {
                this.maximoscroll = document.getElementsByClassName('day-view')[0].scrollWidth;

            }

            //console.log("slider", this.slider);
            document.getElementsByClassName("day-view")[0].scrollLeft = this.slider;
            
        },
        pruebascroll() {
            setTimeout(() => {
                    let aux = document.getElementsByClassName('day-view')[0];
                    aux.addEventListener('scroll', this.pruebaexito)

            }, 4000);
  
        },
        pruebaexito(){
          //  console.log("exito");
            this.slider = document.getElementsByClassName("day-view")[0].scrollLeft;
            if (document.getElementsByClassName('day-view')[0].scrollLeftMax){
                this.maximoscroll = document.getElementsByClassName('day-view')[0].scrollLeftMax;
            }
            if (document.getElementsByClassName('day-view')[0].scrollWidth) {
                this.maximoscroll = document.getElementsByClassName('day-view')[0].scrollWidth;

            }


        },
        async cargarespecialidadesoperarios() {
            //GET   modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/especialidades
            const api = new PwgsApi();
            let datos;
            datos = await api.get("planning-pwgs/0/especialidades");
            this.especioperario = datos;
        },
        async cargarmarcas() {
            //GET  modulos/pwgsapi/index.php/tipos-marca
            const api = new PwgsApi();
            let datos;
            datos = await api.get("tipos-marca");
            this.marcas = datos.datos;
        },
        async cargartiposservicio() {
            //GET  modulos/pwgsapi/index.php/tipos-marca
            const api = new PwgsApi();
            let datos;
            datos = await api.get("tipos-servicio");
            this.tiposservicio = datos.datos;
        },
        async filtraroperarios() {
            //POST  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/operarios
            const api = new PwgsApi();
            this.operariosaux = "";
            let subidadatos = {};
            if (this.marcaseleccionada) { subidadatos.id_marca = this.marcaseleccionada; }
            if (this.especioperarioseleccionado) { subidadatos.id_especialidad = this.especioperarioseleccionado; }
            if (this.tiposservicioseleccionado) { subidadatos.id_tipo_servicio = this.tiposservicioseleccionado; }
            if (this.sat) { subidadatos.sat = this.sat; }
            if (this.guardia) { subidadatos.guardia = this.guardia; }
            let operariosaux = '';
            operariosaux = await api.post("planning-pwgs/0/operarios", subidadatos);
            if (this.marcaseleccionada || this.especioperarioseleccionado || this.sat) this.operariosseleccionados = [];
            if (this.operariosaux == '') { this.operariosseleccionados = []; }
            let aux = [];
            for (let operario of Object.entries(operariosaux)) {
                aux.push(operario[1].idoperario);
            }
            this.operariosseleccionados = aux;
        },
        async comprobarEquipos(){
            const api = new PwgsApi();
            console.log('llegacomprobar');
            const ia = await api.get('parametros-pwgs/gestion_de_equipos');
            if(ia['gestion_de_equipos']==1){
                this.equipos=true;
            }else{
                this.equipos=false;
            }
        },
        async comprobarTiposServicio(){
            const api = new PwgsApi();
            let aux = await api.get('parametros-pwgs/gestion_tipos');
            if(aux.gestion_tipos == 1){
                this.gestiontipos = true
            }
            else{
                this.gestiontipos = false}
        },
        async comprobarGuardias(){
            const api = new PwgsApi();
            let aux = await api.get('parametros-pwgs/guardias');
            if(aux.guardias == 1){
                this.guardias = true;
            }
            else{
                this.guardias = false;
            }
        }
    },
    mounted() {
        this.comprobarEquipos();
        this.cargaroperarios();
        this.cargarespecialidadesoperarios();
        this.comprobarTiposServicio();
        this.comprobarGuardias();
        this.cargarmarcas();
        this.cargartiposservicio();
        this.component = "servicio-planning-mapa";
        this.component = "servicio-planning-calendario";
        this.component = "servicio-planning-calendario";
        this.pruebascroll();
    },
    unmounted() {
        window.removeEventListener('scroll', this.pruebaexito);
    },
    watch: {
        slider() {
        },
        tiposservicioseleccionado() {
            if (this.tiposservicioseleccionado) {
                this.cambios = true;
                //this.marcaseleccionada = '';
                //this.sat = '';
                //this.especioperarioseleccionado = '';
                //this.guardia = '';
                this.filtraroperarios();
            }

            if (this.tiposservicioseleccionado == '') { this.operariosseleccionados = ''; this.cambios = false }

        },
        marcaseleccionada() {
            if (this.marcaseleccionada) {
                //this.cambios = true;
                //this.sat = '';
                //this.tiposservicioseleccionado = '';
                //this.especioperarioseleccionado = '';
                //this.guardia = '';
                this.filtraroperarios();
            }
            if (this.marcaseleccionada == '') { this.operariosseleccionados = ''; this.cambios = false }
        },
        sat() {
            if (this.sat) {
                this.cambios = true;
                //this.marcaseleccionada = '';
                //this.tiposservicioseleccionado = '';
                //this.especioperarioseleccionado = '';
                //this.guardia = '';
                this.filtraroperarios();
            }
            if (this.sat == '') { this.operariosseleccionados = ''; this.cambios = false }
        },
        guardia() {
            if (this.guardia) {
                this.cambios = true;
                //this.marcaseleccionada = '';
                //this.tiposservicioseleccionado = '';
                //this.especioperarioseleccionado = '';
                //this.sat = '';
                this.filtraroperarios();
            }
            if (this.guardia == '') { this.operariosseleccionados = ''; this.cambios = false }
        },
        especioperarioseleccionado() {
            console.log('llegaespeciop', this.especioperarioseleccionado);
            if (this.especioperarioseleccionado) {
                this.cambios = true;
                //this.marcaseleccionada = '';
                //this.tiposservicioseleccionado = '';
                //this.sat = '';
                //this.guardia = '';
                this.filtraroperarios();
            }
            if (this.especioperarioseleccionado == '') { this.operariosseleccionados = ''; this.cambios = false }
        },
        $route() {
            this.slider = 0;
        }
       
    }
})
</script>
<style>
.p-multiselect-items-wrapper {
    border: 1px solid;
    color: #c1c1c1;
}
</style>